import ResultsProgress from 'components/ResultsProgress';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import styled from 'styled-components';
import { tablet, useQuery } from 'styles/breakpoints';
import { usePageView, useQuizData } from 'utils/hooks';
import { normalizeStates } from 'utils/localization';

import Logo from 'assets/icons/logo.svg';
import { DynamicImage, PrimaryButton } from 'components';
import { useRouter } from 'apis/history';

const ResultsHabits: FC = () => {
  const [loading, setLoading] = useState(false);
  const { isTablet } = useQuery();
  const data = useQuizData('resultsHabits');
  const user = useSelector((state: AppState) => state.user);
  const { goToResultsSummary } = useRouter();
  const handleClick = () => {
    setLoading(true);
    goToResultsSummary();
  };

  usePageView({
    client_code: user.code,
    city: encodeURIComponent(
      user.geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    country: user.geolocation?.iso_country?.toLowerCase(),
    state: normalizeStates(
      user.geolocation?.iso_country || '',
      user.geolocation?.iso_state || '',
    ),
    gender: user.quiz_answers?.gender ? user.quiz_answers?.gender[0] : null,
    email: user.user?.email,
  });

  return (
    <Container>
      <Inner>
        <LogoStyled />
        <ResultsProgress
          currentStep={data?.currentStep}
          totalSteps={data?.totalSteps}
        />
        <Card>
          <Title>{data?.title}</Title>
          <ChartContainer>
            <ImgContainer>
              <StyledImg src={isTablet ? data?.imgMob : data?.img} alt="" />
            </ImgContainer>
            <SideContainer>
              <LeftSide>
                <LeftTitle>{data?.nowTitle}</LeftTitle>
                <ListBox>
                  {data?.nowList.map((item: string, index: number) => (
                    <ListItem
                      key={index}
                      dangerouslySetInnerHTML={{ __html: item }}
                    />
                  ))}
                </ListBox>
              </LeftSide>

              <RightSide>
                <RightTitle>{data?.afterTitle}</RightTitle>
                <ListBox>
                  {data?.afterList.map((item: string, index: number) => (
                    <ListItem
                      key={index}
                      dangerouslySetInnerHTML={{ __html: item }}
                    />
                  ))}
                </ListBox>
              </RightSide>
            </SideContainer>
          </ChartContainer>
          <ButtonContainer>
            <StyledButton onClick={handleClick} loading={loading}>
              {data?.buttonText}
            </StyledButton>
          </ButtonContainer>
        </Card>
      </Inner>
    </Container>
  );
};

export default ResultsHabits;

const Container = styled.section`
  background: #ffe7de;
  padding: 2rem 1rem;
  @media ${tablet} {
    padding: 0 1rem 2rem;
  }
  min-height: 100vh;
`;

const Inner = styled.section`
  max-width: 31.25rem;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LogoStyled = styled(Logo)`
  margin: 1.25rem auto;
`;

const Card = styled.section`
  display: flex;
  width: 100%;
  padding: 1.5rem 0;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;
  background: #fff;
  box-shadow: 0px 4.935px 16px 0px rgba(0, 0, 0, 0.08);
  margin-top: 1.5rem;
  padding: 1.5rem 1rem;

  @media ${tablet} {
    margin-top: 2rem;
  }
`;

const Title = styled.h1`
  color: #28293d;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 800;
  line-height: 130%;
  letter-spacing: -0.063rem;

  @media ${tablet} {
    font-size: 1.25rem;
    letter-spacing: -0.02rem;
  }
`;

const StyledImg = styled(DynamicImage)`
  height: 100%;
  width: 100%;
  max-width: 29.25rem;
  max-height: 8.625rem;

  @media ${tablet} {
    max-width: 19.375rem;
    max-height: 7.25rem;
  }
`;

const ImgContainer = styled.section`
  width: 100%;
  /* max-width: 25.0625rem; */
  /* margin: 1.5rem auto; */
  max-height: 8.125rem;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 2.41rem;

  @media ${tablet} {
    /* margin: 1.5rem 0.53rem;
    max-height: 7.25rem;
    max-width: 20.375rem; */
    margin-bottom: 2.39rem;
  }
`;

const ButtonContainer = styled.section`
  max-width: 31.25rem;
  width: 100%;
  /* padding: 0 1rem; */
`;

const StyledButton = styled(PrimaryButton)`
  border-radius: 6.25rem;
  background: #804da1;
  box-shadow: 0px 0px 1px 0px rgba(40, 41, 61, 0.04),
    0px 2px 4px 0px rgba(96, 97, 112, 0.16);
  width: 100%;
`;

const ChartContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1.5rem 0;
  padding: 1rem 0.5rem;
  border-radius: 0.75rem;

  background: rgb(227, 136, 128);
  background: linear-gradient(
    90deg,
    rgba(227, 136, 128, 1) 50%,
    rgba(151, 215, 165, 1) 50%
  );
`;

const SideContainer = styled.div`
  display: flex;
  width: 100%;
`;

const LeftSide = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  border: 1px solid #e38880;
  border-right: 0;
  background: #e38880;

  @media ${tablet} {
    /* padding: 1rem 0.5rem; */
  }
`;

const RightSide = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  border: 1px solid #97d7a5;
  border-left: none;
  background: #97d7a5;
  margin-left: 1rem;

  @media ${tablet} {
    /* padding: 1rem 0.5rem; */
  }
`;

const LeftTitle = styled.p`
  color: #000;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 900;
  line-height: 140%;

  @media ${tablet} {
    font-size: 1.125rem;
  }
`;

const RightTitle = styled(LeftTitle)``;

const ListBox = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
`;

const ListItem = styled.p`
  color: #28293d;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  @media ${tablet} {
    span {
      display: block;
    }
  }
`;
